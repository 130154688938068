import React, {useState, useEffect, useRef}  from 'react'
import ReactLoading from 'react-loading';
import { getUserData} from '../firebase.js'
import Swal from 'sweetalert2'

import { login } from '../services/participants.service.js';
import MoreIcon from '../assets/MoreIcon.jsx';
import Questions from './Questions.jsx';

const Activity = ({style, eventId, questions})=> {
  const [state, setState] = useState({})
  const [loggedIn, setLoggedIn] = useState(false)
  const [round, setRound] = useState(-1)
  const [tables, setTables] = useState([])
  const [loading,setLoading ] = useState(false)
  const [id, setId ] = useState('')
  const [ready, setReady] = useState(false)
  const [userData, setUserData] = useState({})
  const [displayQuestions, setDisplayQuestions] = useState(false)
  const inputRef = useRef(null)
  const toggleDisplayQuestions = () => { 
    setDisplayQuestions(!displayQuestions)
  }
  const handleChange = (e) =>{
    setState({...state, [e.target.name]: e.target.value})
  }
  const [logoHeight, setLogoHeight] = useState(0);
  const logoRef = useRef(null);
  useEffect(() => {
    if (logoRef.current) {
      setLogoHeight(logoRef.current.naturalHeight); // Get the image's natural height
    }
  }, []);
 
  const getTables = async(email) => { 
    try{
     setLoading(true)
     email = email.replace(/ /g, '');
      const res = await login({email, eventId})
      console.log(res.data)
      const user = res.data.response.user
      localStorage.setItem('speedNetworkingEventId', eventId)

      localStorage.setItem('speedNetworkingEmail', email)
      localStorage.setItem('speedNetworkingId', user.id)
      setId(user.id)
      getUserData(user.id, setUserData, setRound)

      setLoading(false)
      setLoggedIn(true)
      
    }catch(err){ 
      console.log(err)
      setLoading(false)
     
      if(err.response){

        if(err.response?.status == 401){ 
          Swal.fire({
            icon: 'warning',
            title: 'You are not a participant!',
            text: 'Use the email that you singed on with.',
            confirmButtonColor: style.buttonBg,

          })
          
          localStorage.removeItem('speedNetworkingId')
          localStorage.removeItem('speedNetworkingEventId')
          localStorage.removeItem('speedNetworkingEmail')
          
        }else{
          Swal.fire({
            icon: 'error',
            title: 'Something went wrong!',
            confirmButtonColor: style.buttonBg,

          })
        }
      }else{
        Swal.fire({
          icon: 'error',
          title: 'Something went wrong!',
          confirmButtonColor: style.buttonBg,

        })
      }
      }
    }
  
  const handleSubmit = async (e) => {
    e.preventDefault()
    try{
      if(!state.email){ 
        Swal.fire({
          icon: 'error',
          title: 'Email is required',
          confirmButtonColor: style.buttonBg,

        })
        return
      }
      await getTables(state.email)
    }catch(err){ 
      console.log(err)
    }
  }

  const initial = async() =>{ 
    try{ 

      const email = localStorage.getItem('speedNetworkingEmail')
      const localEventId = localStorage.getItem('speedNetworkingEventId')
      if(email && localEventId == eventId){ 
        getTables(email)
      }
  }catch(err){ 
      setLoading(false)
  }
  }
  useEffect(() => {

    initial()
  }, [])

 
  return (
      <div className="App" style={{background:style.bg}}  >

   <style> 
              

                {`
                @font-face {
                  font-family: "CustomFont";
                  src: url(${style.font})  format("opentype");
                  font-weight: bold;
                  font-style: normal;
                }

                body{
                  font-family:  CustomFont , Helvetica !important;
                  background: ${style.bg}
                }
                `}
                  {` 

                    input{
                      font-family:  CustomFont , Helvetica !important;

                    } 
                    ::placeholder { 
                        color: ${style.inputPlaceholder}; 
                        font-family:  CustomFont , Helvetica !important;
                    }
                    .questions__container::-webkit-scrollbar-thumb {
                      background: ${style.questionBtn};
                      border-radius: 5px;
                      }
                    ` 
                } 
            </style> 
      <div className='header'>
      <div className='header__image__container' style={{width:style.logoWidth, marginTop:style.logoMarginTop}}>

        <img ref={logoRef} src={style.logo} className="header__image"/> 
      </div>
      </div>
      <div >
    {loading? 
      <div className='center__container'>

<ReactLoading type='bars' color={style.loading}/> 
  </div>
        :
        <>
      {!loggedIn? 
        <form onSubmit={handleSubmit} className={'content_container'} style={{background: style.popupBg}}>
          <div className='content_wrapper'>
        <p className='login__text'  style={{color: style.title}}>To get started kindly enter your email </p>
        <h2 className='input__title'  style={{color: style.title}}>Email</h2>

        <input id="input" ref={inputRef} onChange={handleChange}  style={{background: style.inputBg, color:style.inputText }} placeholder="Email address" type='email' className='email__input' name="email" /> 
        <input type={'submit'}  className="submit__btn" style={{background: style.buttonBg, color: style.buttonText }} /> 
        </div>

        </form>
        : <> 
        {round< 0|| !userData.table? 
          <div className='center__container'>
            
           <div>
           <h2 className='waiting__title' style={{color: style.loadingTitle}}>Please wait for next round</h2>
            
          <div className='waiting__loading'>
    <ReactLoading type='bars'  color={style.loading}/> 
             </div>
           </div>
  </div>
        :
        <div className='content_container'>
          <div className='content_wrapper'>
          <div className='page__container'>

            {displayQuestions? <Questions questions={questions} round={round} style={style} userData={userData} logoHeight= {logoHeight} /> :
          <div className="numbers__container">
          <div className="number__container">
          <h3 className='title' style={{color: style.title}}>Round</h3>
          <h3 className='number' style={{color: style.numbers}}>{round+1}</h3>

          </div>
          <div className="number__container">

          <h3 className='title' style={{color: style.title}}>Table</h3>
          <h3 className='number' style={{color: style.numbers}}>{userData.table} </h3>
          </div>
          </div>

}
          </div>

          {questions[round]?  
      <button className="submit__btn" onClick={toggleDisplayQuestions} style={{background: style.buttonBg, color: style.buttonText }} > {displayQuestions?'Done' :'Open Questions'}</button> 
          :<div style={{marginTop: '40px'}}></div> }
      </div>

          </div>
        }
          </>}
        </>}

    </div>
        <div className='footer'> 
        <img src={style.pattern} className="footer__image"/> 
        </div>

        </div>
  );
}

export default Activity