import React, { useEffect, useState, useRef } from "react";

export default function Questions({ questions, round, style, userData }) {
  const logoRef = useRef(null);
  const [logoHeight, setLogoHeight] = useState(0);

  useEffect(() => {
    if (logoRef.current) {
      setLogoHeight(logoRef.current.offsetHeight);
    }

    // Adjust on window resize (especially for mobile)
    const handleResize = () => {
      if (logoRef.current) {
        setLogoHeight(logoRef.current.offsetHeight);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const formatQuestions = (q) => {
    const q1 = q.split("-n---t---n-");
    if (q1.length > 1 && !(q1.length < userData.track)) {
      return q1[userData.track - 1].split("-n-");
    } else if (q1.length > 1 && q1.length < userData.track) {
      return q1[q1.length - 1].split("-n-");
    }
    return q.split("-n-");
  };

  return (
    <div>
    
    <h3 className="questions__title" style={{ color: style.popupTitle }}>
            Questions for Round {round + 1}
          </h3>
      {questions[round] ? (
        <div
          className="questions__container"
          style={{
            maxHeight: `calc(100vh - ${logoHeight}px - 520px)`, // Dynamic height
            overflowY: "auto",
            background: style.popupBg,
            padding: "15px",
            borderRadius: "12px",
          }}
        >
          

          <ul>
            {formatQuestions(questions[round]).map((question, index) => (
              <li
                key={index}
                className="question__content"
                style={{ color: style.popupText, padding: "5px 0" }}
              >
                {question}
              </li>
            ))}
          </ul>
        </div>
      ) : null}
    </div>
  );
}
